import gql from 'graphql-tag'

export const GET_GATHERINGS = gql`
  query gatherings(
    $buUuids: [GUID]
    $dateFrom: Date
    $dateTo: Date
    $gatheringFormat: [GatheringFormat]
    $international: [GatheringFilterInternational]
    $rocUuids: [GUID]
    $searchText: String
    $type: GatheringFilterType
    $when: [GatheringFilterWhen]
    $limit: Int
    $offset: Int
  ) {
    gatherings(
      buUuids: $buUuids
      dateFrom: $dateFrom
      dateTo: $dateTo
      gatheringFormat: $gatheringFormat
      international: $international
      rocUuids: $rocUuids
      searchText: $searchText
      type: $type
      when: $when
      limit: $limit
      offset: $offset
    ) {
      gatheringUuid
      beginDate
      biogenEvent {
        availablePlace
      }
      businessUnits {
        label
      }
      city
      congress {
        congressUuid
        hasAutomatedValidation
        hasZoneQuota
        maximalQuota
        otherQuota
        nationalityType
        solicitationEndDate
        stats {
          otherFilled
          totalFilled
          zones {
            filledZoneQuota
            zoneUuid
          }
        }
        solicitationEndDate
        zoneCongresses {
          zoneQuota
          zoneUuid
          zone {
            label
            businessUnit {
              label
            }
          }
        }
      }
      importedAttendees {
        firstName
        lastName
      }
      endDate
      eveniumEventId
      gatheringType
      isPhysical
      isVirtual
      location
      logo
      name
      status
    }
  }
`

export const GET_GATHERINGS_CONFIRMED_INVITATIONS = gql`
  query confirmedInvitationsGatherings(
    $buUuids: [GUID]
    $dateFrom: Date
    $dateTo: Date
    $gatheringFormat: [GatheringFormat]
    $international: [GatheringFilterInternational]
    $searchText: String
    $type: GatheringFilterType
    $when: [GatheringFilterWhen]
    $limit: Int
    $offset: Int
  ) {
    gatherings(
      buUuids: $buUuids
      dateFrom: $dateFrom
      dateTo: $dateTo
      gatheringFormat: $gatheringFormat
      international: $international
      searchText: $searchText
      type: $type
      when: $when
      limit: $limit
      offset: $offset
    ) {
      gatheringUuid
      biogenEvent {
        confirmedInvitations
      }
    }
  }
`

export const GET_GATHERINGS_FOR_EVENT_SEARCH = gql`
  query getGatherings($eventName: String) {
    gatherings(limit: 1000, searchText: $eventName, type: CONGRESS) {
      gatheringUuid
      name
      beginDate
      endDate
      city
      country
      status
      gatheringBusinessUnits {
        gatheringBusinessUnitUuid
        businessUnitUuid
        solicitationEndDate
      }
      congress {
        congressUuid
        maximalQuota
        nationalityType
        otherQuota
        solicitations {
          healthCareProfessional {
            veevaId
          }
        }
        stats {
          totalFilled
        }
        zoneCongresses {
          zoneUuid
          zoneQuota
        }
      }
    }
  }
`

export const GET_MYBB_GATHERINGS = gql`
  query gatherings(
    $buUuids: [GUID]
    $dateFrom: Date
    $dateTo: Date
    $gatheringFormat: [GatheringFormat]
    $international: [GatheringFilterInternational]
    $rocUuids: [GUID]
    $searchText: String
    $type: GatheringFilterType
    $when: [GatheringFilterWhen]
    $limit: Int
    $offset: Int
    $isCongrexBo: Boolean = true
  ) {
    gatherings(
      buUuids: $buUuids
      dateFrom: $dateFrom
      dateTo: $dateTo
      gatheringFormat: $gatheringFormat
      international: $international
      rocUuids: $rocUuids
      searchText: $searchText
      type: $type
      when: $when
      limit: $limit
      offset: $offset
      isCongrexBo: $isCongrexBo
    ) {
      gatheringUuid
      categoryVersion
      beginDate
      endDate
      createdAt
      name
      isPhysical
      isVirtual
      status
      gatheringBusinessUnits {
        gatheringBusinessUnitUuid
        doctorQuota
        otherDisciplinesQuota
        solicitationEndDate
        businessUnitUuid
        hasZoneQuota
        businessUnit {
          externalId
          label
        }
        subscribedUsers {
          doctors
          others
          zone {
            businessUnitUuid
            label
          }
          zoneCongress {
            zoneQuota
          }
          zoneUuid
        }
      }
      importedAttendees {
        firstName
        lastName
        status
      }
      users {
        businessUnitUuid
        gatheringUserType
        isBackup
        userUuid
        user {
          firstName
          lastName
        }
      }
      participants {
        healthCareProfessionalUuid
        participantUuid
        invitationStatus
        userUuid
        category
      }
      mainBusinessUnitUuid
      gatheringType
      congress {
        congressUuid
        zoneCongresses {
          zoneCongressUuid
          zoneQuota
          zoneUuid
          zone {
            businessUnitUuid
            label
          }
        }
      }
      biogenEvent {
        biogenEventUuid
        availablePlace
      }
    }
  }
`
