<template>
  <v-tooltip :color="color" top>
    <template v-slot:activator="{ on, attrs }">
      <v-progress-linear
        v-if="data.value > 0 || placeholder"
        :height="height"
        :style="{ width: `${width}%` }"
        :value="placeholder ? 0 : 100"
        :color="color"
        v-on="on"
        v-bind="attrs"
      >
        <!-- Hide content if the bar is equal or less than 10% of the bar width -->
        <template v-slot:default v-if="width > 10">
          <mybb-text weight="bold" class="white--text">{{ label }}</mybb-text>
        </template>
      </v-progress-linear>
    </template>

    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'DataBar',
  props: {
    data: {
      type: Object,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 30
    },
    placeholder: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: Number,
      required: false,
      default: 100
    }
  },
  data() {
    return {
      badge: false
    }
  },
  computed: {
    color() {
      if (!this.data.light) return this.data.color

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.data.color)

      return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.5)`
    },
    label() {
      const [int, decimals] = this.data.percentage.toFixed(2).split('.')
      let percentage = int

      if (decimals !== '00') {
        percentage += `,${decimals}`
      }

      return `${percentage}% (${this.data.value})`
    }
  }
}
</script>

<style scoped lang="scss"></style>
