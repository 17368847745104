<template>
  <div v-if="total > 0">
    <div class="CompletionBar" v-if="overflow">
      <data-bar :data="overflow" :height="height" :width="100" />
    </div>
    <div class="CompletionBar" v-else>
      <data-bar
        v-for="(point, index) in data"
        :key="`${index}-${point.value}`"
        :data="point"
        :height="height"
        :width="point.percentage"
      />
      <data-bar
        placeholder
        key="completion-placeholder"
        :data="remainingData"
        :height="height"
        :width="remainingData.percentage"
      />
    </div>

    <!-- Legend -->
    <div v-if="legend" class="Legend mt-2">
      <span v-for="(point, index) in data" :key="`${index}-${point.value}`">
        <v-badge left inline dot :color="pointColor(point)" />
        <mybb-text>{{ point.legend }}</mybb-text>
      </span>
    </div>
  </div>
</template>

<script>
import DataBar from './DataBar'

/**
 * Definition of each data point in the data props
 * @typedef {{
 *  value: number
 *  percentage: number - 0 to 100
 *  color: string
 *  light: boolean
 * }} CompletionData
 */

export default {
  name: 'CompletionBar',
  components: { DataBar },
  props: {
    data: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 30
    },
    total: {
      type: Number,
      required: false,
      default: 0
    },
    legend: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    remainingData() {
      const totalValueOccupied = this.data.reduce((acc, point) => acc + (point.value || 0), 0)
      const value = this.total - totalValueOccupied
      const percentage = this.total > 0 ? (value * 100) / this.total : 100

      return {
        value,
        percentage,
        color: 'error'
      }
    },
    overflow() {
      if (this.remainingData.percentage >= 0) {
        return false
      }

      const value = this.data.reduce((acc, point) => acc + (point.value || 0), 0)
      const percentage = this.total > 0 ? (value * 100) / this.total : 100

      return {
        value,
        percentage,
        color: 'error'
      }
    }
  },
  methods: {
    pointColor(point) {
      if (!point.light) return point.color

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(point.color)

      return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.5)`
    }
  }
}
</script>

<style scoped lang="scss">
.CompletionBar {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow-x: hidden;
}
</style>
